:root {
    /* * Colors * */
    /* Primary color */
    --primary-25: rgb(244, 255, 254);
    --primary-50: rgba(230, 246, 245, 1);
    --primary-100: rgba(176, 226, 224, 1);
    --primary-200: rgba(138, 212, 209, 1);
    --primary-300: rgba(84, 192, 189, 1);
    --primary-400: rgba(51, 180, 176, 1);
    --primary-500: rgba(0, 161, 156, 1);
    --primary-600: rgba(0, 147, 142, 1);
    --primary-700: rgba(0, 88, 86, 1);
    --primary-800: rgba(0, 62, 60, 1);
    --primary-900: rgba(0, 46, 44, 1);
  
    /* Neutral color */
    --neutral-25: rgba(252, 252, 253, 1);
    --neutral-50: rgba(249, 250, 251, 1);
    --neutral-100: rgba(242, 244, 247, 1);
    --neutral-200: rgba(234, 236, 240, 1);
    --neutral-300: rgba(208, 213, 221, 1);
    --neutral-400: rgba(153, 154, 173, 1);
    --neutral-500: rgba(106, 115, 133, 1);
    --neutral-600: rgba(78, 88, 103, 1);
    --neutral-700: rgba(55, 56, 72, 1);
    --neutral-800: rgba(43, 43, 61, 1);
    --neutral-900: rgba(32, 32, 45, 1);
    --neutral-950: rgba(18, 18, 30, 1);
  
    /* Accent color. dark 700, medium 400, light 200 */
    --accent-success-dark: rgba(2, 122, 72, 1);
    --accent-success-medium: rgba(50, 213, 131, 1);
    --accent-success-light:rgba(166, 244, 197, 1);
  
    --accent-warning-dark: rgba(181, 71, 8, 1);
    --accent-warning-medium: rgba(253, 176, 34, 1);
    --accent-warning-light: rgba(254, 223, 137, 1);
  
    --accent-error-dark: rgba(180, 35, 24, 1);
    --accent-error-medium: rgba(249, 112, 102, 1);
    --accent-error-light: rgba(254, 205, 202, 1);
  
    --accent-purple-dark: rgba(89, 37, 220, 1);
    --accent-purple-medium: rgba(155, 138, 251, 1);
    --accent-purple-light: rgba(217, 214, 254, 1);
  }