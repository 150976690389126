@import url('../styles/colors.css');

.login-field {
    padding: 5px;
    border-radius: 5px;
    text-decoration: none;
    border-width: 1px;
}

.login-button {
    background-color: var(--primary-400);
    color: white;
}

.login-button:hover {
    background-color: var(--primary-500);
}

.login-label {
    color: black;
    font-size: 12px;
    padding-bottom: 1px;
}