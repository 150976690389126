@import url('../../styles/colors.css');

.compare-intersection-select {
    background-color: var('--neutral-500');
    border-radius: 5px;
    padding: 3px;
    margin-bottom: 10px;
    color: var('--neutral-500');
    text-decoration: none;
    font-size: 14px;
}