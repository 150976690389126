@import url('./styles/text.css');
@import url('./styles/colors.css');
@import 'react-datepicker/dist/react-datepicker';

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
label {
  color: white;
  padding: 0;
  margin: 0;
}

div {
  background-color: transparent;
}

dialog {
  background-color: #20202D;
  border-radius: 10px;
  border: 3px;
}

button {
  background-color: #2B2B3D;
  border-radius: 5px;
  border: #2B2B3D 1px;
  color: #D0D5DD;
  cursor: pointer;
  padding: 5px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 500;
  font-size: 15px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.side-nav {
  width: 80px !important;
  min-width: 80px;
  background-color: #20202D;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
}

.button-container {
  width: 80%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav-button {
  width: 100%;
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
  border-radius: 20%;
  padding-top: 10px;
  padding-bottom: 10px;
}



.nav-button:hover {
  background-color: #12121E;
}

.main-div {
  flex-grow: 1;
  background-color: #2B2B3D;
  /* overflow-y: auto; */
}

.nav {
  height: 60px;
  /* Update the height to a suitable value for your navigation bar */
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
}


/* Media query for screens with width less than or equal to 600px */
@media (max-width: 600px) {
  .side-nav {
    display: none;
  }

  .content {
    width: 100%;
  }
}


.overlay {
  position: fixed;
  top: 65px;
  bottom: 10px;
  right: 10px;
  width: 45%;
  z-index: 99;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #20202D;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  overflow: auto;
}

.overlay-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.overlay-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.metric-view {
  max-height: fit-content;
  overflow-y: auto;
  scrollbar-width: none;
  padding-bottom: 5px;
}

/* These next two classes hide scrollbar on chrome and safari */
.metric-view::-webkit-scrollbar{
  width: 0;
}

.metric-view::-webkit-scrollbar-thumb {
  background: transparent;
}

.overlay::-webkit-scrollbar{
  width: 0;
}

.overlay::-webkit-scrollbar-thumb {
  background: transparent;
}

.annotation-side-panel {
  margin: 10px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-height: 800px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 60px;
  max-width: 350px;
  overflow-y: auto;
  scrollbar-width: none;
}

.annotation-side-panel::-webkit-scrollbar{
  width: 0;
}

.annotation-side-panel::-webkit-scrollbar-thumb {
  background: transparent;
}

.custom-marker {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid;
  cursor: pointer;
  background-color: rgba(#32D583, 0.5);
}

.top-buttons {
  position: fixed;
  font-size: 16px;
  font-weight: 400;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* Align items to the top */
  z-index: 999;
  gap: 5px;
}

.top-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  z-index: 100;
  padding: 5px;
  background-color: #20202D;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
}

.top-button.active {
  border-radius: 20px 20px 0px 0px;
  padding-bottom: 10px;
}

.widget-menu {
  min-width: 20%;
  position: fixed;
  top: 65px;
  z-index: 100;
  right: 10px;
  background-color: #20202D;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.date-picker-menu {
  right: 350px !important;
}

#map {
  z-index: 10;
}

p {
  margin: 0;
}

.select-metric {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-basis: auto;
  margin-bottom: 10px;
}

.select-metric button {
  background: none;
  cursor: pointer;
  padding: 0;
  padding-bottom: 10px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid grey;
  border-radius: 0%;
  font-weight: 200;
  flex-grow: 1;
}


.select-metric button.active {
  color: #00A19C;
  border-color: #00A19C;
  border-width: 2px;
}

.intersection-widget ul {
  list-style-type: none;
}

.intersection-widget ul li {
  color: white;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 8px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.grid-item {
  border: 1px solid #2B2B3D;
  border-radius: 10px;
}

.grid-item .content {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#upload-modal {
  min-width: 20%;
  min-height: 15%;
}

.modal-content {
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: flex;
}

#video-input {
  color: white;
}

#date-picker-modal {
  position: absolute;
  margin: 0;
  top: 15%;
  left: 40%;
  background-color: #2B2B3D;
  min-width: 600px;
}

#date-picker-modal::backdrop {
  background-color: rgba(32, 32, 45, 0.5);
}

.date-picker-selection {
  color: #999AAD;
  padding: 8px;
  border-radius: 10px;
  padding-right: 20px;
  cursor: pointer;
}

.date-picker-selection:hover {
  background-color: #373848;
}

/* Inputs */
.annotation-label-input-div {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-bottom: 2px;
}

.annotation-label {
  font-size: 14px;
  font-weight: 400;
  color: '#D0D5DD';
  margin-bottom: 2px;
}

.annotation-input {
  border-radius: 8px;
  text-decoration: none;
  font-family: 'Inter';
  font-size: '20px';
  border: none;
  padding: 3px;
  color: #6A7385;
  padding-left: 6px;
  height: 30px;
  cursor: pointer;
}

.annotation-button {
  padding: 10px;
  margin-left: 5px;
  border-radius: 10px;
}

.annotation-checkbox {
  border-radius: 2px;
  border: 1px #D0D5DD solid;
  background-color: #6A7385;
}

.annotation-input-option {
  flex-grow: 0;
}

.polygon-picker {
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 5px;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px #2B2B3D solid;
}

.polygon-picker:hover {
  background-color: #00938E !important;
}

.help-icon {
  width: 20px;
  margin-right: 8px;
  border-radius: 25%;
  color: white;
}

.help-icon:hover {
  background-color: #6A7385;
}


.video-player {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.video-navigation {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  gap: 5px;
}

.translate-right {
  transition: transform 0.3s ease-in-out;
}

.translate-right:hover {
  transform: translateX(5px);
}

.icon-button {
  padding-bottom: 2px;
  padding-top: 2px;
}

.text-input {
  padding: 6px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 24px;
  color: #6A7385;
  padding-left: 8px;
  min-width: 200px;
}

/* Date picker classes */

.react-datepicker {
  font-family: 'Inter' !important;
  background-color: #2B2B3D !important;
  color: #6A7385;
  border: none !important;
}

.react-datepicker__header {
  background-color: #2B2B3D !important;
  border-bottom: none !important;
}

.react-datepicker__current-month {
  background-color: #2B2B3D;
  color: #999AAD !important;
}

.react-datepicker__day-name.react-datepicker__day.react-datepicker__time-name {
  margin: 0 !important;
}

.react-datepicker__day-names {
  margin: 0 !important;
}

.react-datepicker__day-name {
  color: #999AAD !important;
  margin: 0 !important;
}

.react-datepicker__day {
  color: #999AAD !important;
  margin: 0 !important;
  /* padding: 1px; */
}

.react-datepicker__day.react-datepicker__day--outside-month {
  background-color: #2B2B3D;
}

.react-datepicker__day.react-datepicker__day--range-start {
  background: linear-gradient(to right, #00938E 0%, #00938E 85%, #6A7385 100%);
  color: white !important;
  border-radius: 15% 0 0 15% !important;
}


.react-datepicker__day.react-datepicker__day--in-range {
  border-radius: 0%;
  margin: 0 !important;
  background-color: #6A7385;
}

.react-datepicker__day.react-datepicker__day--range-end {
  background: linear-gradient(to left, #00938E 0%, #00938E 85%, #6A7385 100%);
  color: white !important;
  border-radius: 0 15% 15% 0 !important;
}

.react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
  border-radius: 15% !important;
  background-color: #00938E !important;
}

.react-datepicker__day.react-datepicker__day--in-selecting-range {
  border-radius: 0 !important;
  background-color: #6A7385 !important;
}

.react-datepicker__day.react-datepicker__day--selecting-range-start {
  color: white !important;
  background-color: #005856 !important;
}

.react-datepicker__day.react-datepicker__day--selecting-range-end {
  color: white !important;
  background-color: #005856 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background-color: #2B2B3D;
  color: white;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected.react-datepicker__day--in-range {
  background-color: #6A7385;
  color: white;
}


.react-datepicker__week {
  margin-bottom: 2px;
}




.icon-button {
  padding: 6px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
