.notif-card{
  background-color: rgba(31, 41, 55, 0.5);
  transition: all 0.3s ease-in-out;
}

.notif-card:hover{
    background-color: rgba(31, 41, 55, 0.668);
    cursor: pointer;
}

.expanded-notif-card:hover{
    cursor:default
}

.activity-log-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    font-weight: 500; 
    font-size: 16px;
    transition-property: color, background-color, border-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    outline: none;
    color: #ffffff;
    background-color: rgb(31 41 55); 
    padding: 0.5rem;
    gap: 10px
}
  .activity-log-btn:hover {
    background-color: rgb(55 65 81);
  }
  
  .collapseBtn:hover {
    cursor: pointer;
  }

  .filter-input:focus {
    outline: none;
  }

  .filter-option{
    transition: all 0.2s ease-in;
  }
  .filter-option:hover{
    cursor: pointer;
  }