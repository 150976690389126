
.text-xxs {
    font-size: 10px;
    line-height: 16px;
}

.text-xs {
    font-size: 12px;
    line-height: 18px;
}

.text-md {
    font-size: 16px;
    line-height: 24px;
}

.text-lg {
    font-size: 18px;
    line-height: 28px;
}

.text-xl {
    font-size: 20px;
    line-height: 30px;
}

.display-xs {
    font-size: 24px;
    line-height: 36px;
}

.display-sm {
    font-size: 30px;
    line-height: 44px;
}

.display-md {
    font-size: 36px;
    line-height: 44px;
}

.display-lg {
    font-size: 48px;
    line-height: 60px;
}

.display-xl {
    font-size: 60px;
    line-height: 72px;
}

.display-xxl {
    font-size: 72px;
    line-height: 90px;
}